import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../stores/auth";
import { notify } from "notiwind";
//import i18n, { SUPPORT_LOCALES } from "../i18n";

const isOnline = import.meta.env.VITE_APP_ONLINE;
//const authExpired = computed(() => useAuthStore().expired);

const routes = [
	{
		path: "/",
		name: "Home",
		/*component: () => import("../layouts/pre.vue"),*/
		beforeEnter: async (to, from) => {
			const { code, message } = await useAuthStore().checkauth();
			if (code === "ERROR") {
				// Do not display auth problem from root or from signout
				if (
					from.path !== "/" &&
					from.path !== "/logout/no" &&
					from.path !== "/logout/yes"
				) {
					// redirect to user profile
					notify(
						{
							type: "error",
							title: "Authentication Issue",
							text: "Possible reasons: No permissions. Login Session expired. Account disabled.",
						},
						10000,
					);
				}

				return { name: "RequestSignIn" };
			}
			return { name: "Dashboard" };
		},
	},
	{
		path: "/dashboard",
		component: () => import("../layouts/base.vue"),
		meta: { requiresAuth: true },
		children: [
			{
				name: "Dashboard",
				path: "",
				component: () => import("../pages/dashboard.vue"),
				beforeEnter: async (to, from) => {
					const { code, message } = await useAuthStore().checkauth();
					if (code === "ERROR") {
						// redirect to user profile
						return { name: "RequestSignIn" };
					}

					if (useAuthStore().profileOnly) {
						// redirect to user profile
						return { name: "HRProfile" };
					}
				},
			},
		],
	},
	{
		path: "/signin",
		component: () => import("../layouts/pre.vue"),
		children: [
			{
				name: "RequestSignIn",
				path: "",
				component: () => import("../components/AuthRequestSignIn.vue"),
			},
			{
				name: "AutoSignIn",
				path: ":email/:token",
				component: () => import("../components/AuthAutoSignIn.vue"),
			},
		],
	},
	{
		path: "/callback",
		component: () => import("../layouts/pre.vue"),
		children: [
			{
				name: "AuthCallback",
				path: "",
				component: () => import("../components/AuthCallback.vue"),
			},
		],
	},
	{
		path: "/silent-renew",
		component: () => import("../layouts/pre.vue"),
		children: [
			{
				name: "AuthSilentRenew",
				path: "",
				component: () => import("../components/AuthRenew.vue"),
			},
		],
	},
	{
		path: "/logout",
		component: () => import("../layouts/pre.vue"),
		children: [
			{
				name: "RequestSignOut",
				path: ":silently?",
				props: true,
				component: () => import("../components/AuthSignOut.vue"),
			},
		],
	},
	{
		path: "/clear-cache",
		component: () => import("../layouts/pre.vue"),
		children: [
			{
				name: "ClearCache",
				path: "",
				component: () => import("../components/ClearCache.vue"),
			},
		],
	} /*
  {
    path: "/my-profile",
    component: () => import("../layouts/base.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: () => import("../pages/myprofile.vue"),
        children: [
          {
            path: "",
            name: "MyProfile",
            component: () => import("../components/hrEmployeeProfile.vue"),
          },
          {
            path: "profile-mock",
            component: () => import("../components/hrEmployeeProfileMock.vue"),
          },
          {
            path: "salaries",
            component: () => import("../components/hrEmployeeSalaries.vue"),
          },
          {
            path: "documents",
            component: () => import("../components/hrEmployeeDocuments.vue"),
          },
        ],
      },
    ],
  },*/,
	{
		path: "/hr",
		component: () => import("../layouts/base.vue"),
		meta: { requiresAuth: true },
		children: [
			{
				path: "",
				component: () => import("../pages/hr.vue"),
				children: [
					{
						path: "",
						name: "HRProfile",
						component: () => import("../components/hrEmployeeProfile.vue"),
					},
					{
						path: "salaries",
						name: "HRSalaries",
						component: () => import("../components/hrEmployeeSalaries.vue"),
					},
					{
						path: "documents",
						name: "HRDocuments",
						component: () => import("../components/hrEmployeeDocuments.vue"),
					},
					{
						path: "messages",
						name: "HRMessages",
						component: () => import("../components/hrEmployeeChat.vue"),
					},
				],
			},
		],
	},
	{
		path: "/accounting",
		component: () => import("../layouts/base.vue"),
		meta: { requiresAuth: true },
		beforeEnter: (to, from, next) => {
			if (!useAuthStore().hasFinanceAccess) {
				notify(
					{
						type: "error",
						title: "Access Control Failed",
						text: "You do not have permission to access accounting",
					},
					10000,
				);
				next({ name: "Dashboard" });
			}
			next();
		},
		children: [
			{ path: "", component: () => import("../pages/accounting.vue") },
		],
	},
	{
		path: "/taxation",
		component: () => import("../layouts/base.vue"),
		meta: { requiresAuth: true },
		beforeEnter: (to, from, next) => {
			if (!useAuthStore().hasTaxationAccess) {
				notify(
					{
						type: "error",
						title: "Access Control Failed",
						text: "You do not have permission to access taxation",
					},
					10000,
				);
				next({ name: "Dashboard" });
			}
			next();
		},
		children: [{ path: "", component: () => import("../pages/taxation.vue") }],
	},
	{
		path: "/salary-control/:id?",
		component: () => import("../layouts/base.vue"),
		meta: { requiresAuth: true, withTitle: "Admin Tool" },
		beforeEnter: (to, from, next) => {
			if (!useAuthStore().hasHrSalaryAccess) {
				notify(
					{
						type: "error",
						title: "Access Control Failed",
						text: "You do not have permission to access accounting",
					},
					10000,
				);
				next({ name: "Dashboard" });
			}
			next();
		},
		children: [
			{
				path: "",
				name: "AdminSalaryControl",
				meta: { withTitle: "Admin Tool" },
				component: () => import("../pages/salarycontrol.vue"),
			},
		],
	},
	{
		path: "/inspect-user-id/:id?",
		component: () => import("../layouts/base.vue"),
		meta: { requiresAuth: true, withTitle: "Admin Tool" },
		beforeEnter: (to, from, next) => {
			if (!useAuthStore().account.role.canAccessAllCompanies) {
				notify(
					{
						type: "error",
						title: "Access Control Failed",
						text: "You do not have permission to use the user inspection tool",
					},
					10000,
				);
				next({ name: "Dashboard" });
			}
			next();
		},
		children: [
			{
				path: "",
				name: "Redirection",
				meta: { withTitle: "Admin Tool" },
				component: () => import("../components/inspectUserId.vue"),
			},
		],
	},
	{
		path: "/documents-review",
		component: () => import("../layouts/base.vue"),
		meta: { requiresAuth: true, withTitle: "Admin Tool" },
		beforeEnter: (to, from, next) => {
			if (!useAuthStore().account.role.canAccessAllCompanies) {
				notify(
					{
						type: "error",
						title: "Access Control Failed",
						text: "You do not have permission to access documents review",
					},
					10000,
				);
				next({ name: "Dashboard" });
			}
			next();
		},
		children: [
			{
				path: "",
				name: "AdminDocumentsReview",
				meta: { withTitle: "Admin Tool" },
				component: () => import("../pages/documentsreview.vue"),
			},
		],
	},
	{
		path: "/my-profile",
		meta: { requiresAuth: true, },
		component: () => import("../layouts/base.vue"),
		children: [
			{
				path: "",
				name: "MyProfile",
				component: () => import("../pages/myprofile.vue"),
			},
		],
	},
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: () => import("../components/NotFound.vue"),
	},
	{
		path: "/maintenance",
		name: "Maintenance",
		//component: () => import("../layouts/pre.vue"),
		component: () => import("../components/maintenance.vue"),
		children: [
			{
				path: "2",
				component: () => import("../components/maintenance.vue"),
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from) => {
	/*
	console.log("Router Before Each");
	console.log("From");
	console.dir(from, { depth: null });
	console.log("To");
	console.dir(to, { depth: null });

	console.log("i18n Before");
	console.dir(i18n.vueI18n.global, { depth: null });
	*/

	// Maintenance mode
	const maintenanceRouteName = "Maintenance";
	if (isOnline !== "yes" && to.name !== maintenanceRouteName) {
		return { name: maintenanceRouteName };
	}

	// Navigate back to home if site is back online
	if (isOnline === "yes" && to.name === maintenanceRouteName) {
		return { name: "Home" };
	}

	if (to.meta.requiresAuth && !useAuthStore().isLoggedIn) {
		return {
			name: "Home",
		};
	}
});

export default router;
